export class AppFeatures {
    static ACTIVITY_HIDDEN_COLUMNS = "activityHiddenColumns";
    static SPECIALTY_HIDDEN_COLUMNS = "specialtyHiddenColumns";
    static FILTERS = "filters";
    static FLEXIBLE_ANALYTICS = "flexibleAnalytics";
    static HIDE_PAGES = "hidePages";
}

export interface HidePagesFeatureConfiguration {
    pages: string[];
}
