import { Injectable } from "@angular/core";
import { LgApplicationPermissionsBase } from "@logex/framework/lg-application";

@Injectable({ providedIn: "root" })
export class ApplicationPermissions extends LgApplicationPermissionsBase {
    static SwitchClientContext = "dpl_switch_all_clients";
    static readonly SwitchLanguage: string = "dpl_switch_language";
    static readonly Internal: string = "dpl_internal";
    static readonly View: string = "dpl_mapdata_view";
    static readonly Edit: string = "dpl_mapdata_edit";
}
