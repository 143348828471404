import { IUser } from "@logex/framework/lg-application";
import { INightingaleUser } from "./nightingale-auth.types";
import { ApplicationPermissions } from "./app-permissions";
/**
 * Representation of UMS 2 user but also a wrapper over LG_USER_INFO
 *   We can't ignore LG_USER_INFO because the current version of FW expects to have it
 * **/
export class NightingaleUser implements INightingaleUser {
    private _lgUser: IUser;

    isDisabled = true;
    isInternal = false;
    impersonator = undefined;
    resourcesCanEdit: Record<string, boolean>;

    constructor(user: IUser) {
        this.resourcesCanEdit = {};
        this._lgUser = user;
    }

    set ziekenhuisnaam(value: string) {
        this._lgUser.ziekenhuisnaam = value;
    }

    get ziekenhuisnaam(): string {
        return this.ziekenhuisnaam;
    }

    set ziekenhuiscode(value: number) {
        this._lgUser.ziekenhuiscode = value;
    }

    get ziekenhuiscode(): number {
        return this._lgUser.ziekenhuiscode;
    }

    set userid(value: string) {
        this._lgUser.userid = value;
    }

    get userid(): string {
        return this._lgUser.userid ?? "";
    }

    set name(value: string) {
        this._lgUser.name = value;
    }

    get name(): string {
        return this._lgUser.name;
    }

    set login(value: string | undefined) {
        this._lgUser.login = value;
    }

    get login(): string {
        return this._lgUser.login ?? "";
    }

    set title(value: string) {
        this._lgUser.title = value;
    }

    get title(): string {
        return this.title;
    }

    set roles(value: Record<string, boolean>) {
        this._setRoleInternal(value);
    }

    get roles(): Record<string, boolean> {
        return this._lgUser.roles;
    }

    public hasPermission(role: string): boolean {
        return this._lgUser.roles[role] || false;
    }

    public canAccessResource(resource: string): boolean {
        return this.resourcesCanEdit[resource] != null;
    }

    public canEditResource(resource: string): boolean {
        return this.resourcesCanEdit[resource] || false;
    }

    private _setRoleInternal(roles: Record<string, boolean>): void {
        this._lgUser.roles = roles;
        if (this.hasPermission(ApplicationPermissions.SwitchClientContext)) {
            this._lgUser.roles["switch_all_clients"] = true;
        }
        if (this.hasPermission(ApplicationPermissions.SwitchLanguage)) {
            this._lgUser.roles["switch_language"] = true;
        }
    }
}
