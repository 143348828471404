import { Injectable, inject } from "@angular/core";

import {
    LG_APP_CONFIGURATION,
    ServerDefinitionsBase,
    DefinitionDisplayMode
} from "@logex/framework/lg-application";

import { IAppDefinitions } from "./app-definition-types";

import { StringKeyOf } from "@logex/framework/types";
export type DefinitionKey = StringKeyOf<IAppDefinitions>;
export { DefinitionDisplayMode };

// function displayCodeFormatter(
//     _code: unknown,
//     item: DefinitionDisplayCodeName,
//     displayMode: DefinitionDisplayMode
// ): string | undefined {
//     return displayMode === "code" && item != null ? item.displayCode : undefined;
// }

@Injectable({
    providedIn: "root"
})
export class AppDefinitions
    extends ServerDefinitionsBase<IAppDefinitions>
    implements IAppDefinitions
{
    constructor() {
        super();

        const appConfiguration = inject(LG_APP_CONFIGURATION);

        this.init();
        this._setBaseUrl(appConfiguration.applicationRoot);
    }
}
