import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, UrlTree } from "@angular/router";
import { WorkflowService } from "@shared/workflow";

export async function OrganizationWorkflowGuard(
    route: ActivatedRouteSnapshot
): Promise<boolean | UrlTree> {
    const workflowId = route.paramMap.get("workflowId");
    const clientId = route.paramMap.get("clientId");
    if (workflowId === null || clientId === null) return false;

    const router = inject(Router);
    try {
        const workflow = await inject(WorkflowService).setCurrentWorkflow(
            parseInt(clientId),
            workflowId
        );
        if (workflow) return true;
    } catch {
        // noop
    }
    // TODO: check internal
    return router.parseUrl("/boot/selectWorkflow");
}
