import { Injectable, inject } from "@angular/core";
import {
    LG_APP_LOAD_INDICATOR,
    LG_APP_SESSION,
    LgAppControlV2Service,
    UserSettingsService,
    WellKnownSettingCode
} from "@logex/framework/lg-application";
import { lastValueFrom } from "rxjs";

@Injectable()
export class AppControlService extends LgAppControlV2Service {
    private _appLoadIndicator = inject(LG_APP_LOAD_INDICATOR);
    private _usersSettingsService = inject(UserSettingsService);
    private _session = inject(LG_APP_SESSION);

    private _noClientSelected = Promise.resolve(false);

    // overriden parent method
    async showContextSelector(): Promise<void> {
        this._appLoadIndicator.show();

        await lastValueFrom(
            this._usersSettingsService.set({
                key: { storageId: WellKnownSettingCode.context },
                value: null
            })
        );
        window.location.assign("/boot/selectWorkflow");
    }

    /**
     * UMS2 user may have different sets of permissions for different clients, e.i we can't check if user obtains switch_all_clients permission without client specified.
     * This fix hides "Switch organization" button on the left sidebar in "Select workflows" page as we don't need it there,
     * the list of accesible workflows is diplayed in the table. Only when users select a specific workflows we will be able to use their current permissions
     */
    get canSwitchClient(): Promise<boolean> {
        if (this._session.client == null) return this._noClientSelected;
        return super.canSwitchClient;
    }

    get canSwitchContext(): Promise<boolean> {
        if (this._session.client == null) return this._noClientSelected;
        return super.canSwitchContext;
    }
}
