import { coerceBooleanProperty, coerceNumberProperty } from "@angular/cdk/coercion";
import { Pipe, PipeTransform, inject } from "@angular/core";
import { ILgFormatter, PercentageFormatterFactory } from "@logex/framework/core";

@Pipe({
    name: "fmtPercentCustom",
    pure: true
})
export class FormatPercentCustomPipe implements PipeTransform {
    private _formatter: ILgFormatter<number>;

    constructor() {
        const formatterFactory = inject(PercentageFormatterFactory);

        this._formatter = formatterFactory.create();
    }

    public transform(
        value: string | number | null | undefined,
        decimals = 0,
        forceSign = false,
        zeroDash = false, // default fmtPercent is set to true
        minMax?: number
    ): string {
        if (value == null) return "-";
        decimals = coerceNumberProperty(decimals);
        forceSign = coerceBooleanProperty(forceSign);

        this._formatter.options = {
            ...this._formatter.options,
            decimals,
            forceSign,
            zeroDash,
            min: minMax == null ? undefined : -minMax,
            max: minMax == null ? undefined : minMax
        };

        return this._formatter.format(+value);
    }
}
