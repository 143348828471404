<lg-col-definition #columns column-class="table__column">
    <lg-col-row id="error" column-class="table-column">
        <lg-col id="expand" type="expand"></lg-col>
        <lg-col id="error" width="*" class="crop"></lg-col>
    </lg-col-row>
    <lg-col-row id="details" column-class="table-column">
        <lg-col id="empty" width="15"></lg-col>
        <lg-col id="error" width="*" class="crop"></lg-col>
    </lg-col-row>
</lg-col-definition>

<lg-rectangle
    class="lg-block--table flexcol flexcol--full"
    [auditTime]="0"
    #rect
    [lgColDefinition]="columns"
    [lgColDefinitionWidth]="rect.width - 10"
>
    <div lgColRow="error" class="table__row table__row--header">
        <div lgCol="error" class="table__column">{{ ".Error_message_header" | lgTranslate }}</div>
    </div>
    <div
        class="lg-pivot-table__body lg-pivot-table__body--flexbox"
        lgScrollable
        style="max-height: 30vh"
    >
        <div *ngFor="let error of _translatedErrors">
            <div
                class="table__row table__row--clickable"
                lgColRow="error"
                (click)="error.$expanded = !error.$expanded"
            >
                <div lgCol="expand">
                    <lg-pivot-table-expand [item]="error"></lg-pivot-table-expand>
                </div>
                <div lgCol="error" [lgLongText]="error.message"></div>
            </div>
            <div class="table__row" lgColRow="details" *ngIf="error.$expanded">
                <div lgCol="empty"></div>
                <div lgCol="error" [lgLongText]="error.details"></div>
            </div>
        </div>
    </div>
</lg-rectangle>
