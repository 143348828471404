import { InjectionToken } from "@angular/core";
import { IUser } from "@logex/framework/lg-application/user";
import { Observable } from "rxjs";

export interface IOrganization {
    organizationId: number;
    agbCode: string;
    cicCode: string;
    mrdmUri: string;
    clientId: number;
    ziekenhuisCode: number;
    name: string;
    position: string;
}

export interface IUserProfile {
    accountId: number;
    email: string;
    firstName: string;
    lastName: string;
    organizations: IOrganization[];
    isDisabled: boolean;
    isInternal: boolean;
    metadata: Record<string, string>;
    // todo: impersonation
}

export interface GetPermissions {
    product: string;
    permissions: string[];
}

export type ResourceType = "Resource.Workflow" | string;

export interface IResource {
    resourceType: ResourceType;
    value: string;
    canEdit: boolean;
}

export interface GetResource {
    product: string;
    resources: IResource[];
}

export interface INightingaleUser extends IUser {
    name: string;
    isDisabled: boolean;
    isInternal: boolean;
    ziekenhuiscode: number;
    ziekenhuisnaam: string;

    hasPermission(role: string): boolean;
    canAccessResource(resource: string): boolean;
    canEditResource(resource: string): boolean;
}

export interface INightingaleAuthService {
    getUserPermission(clientId: string, clientType?: string): Observable<string[]>;
    getUserProfile(): Observable<IUserProfile>;
    getUserOrganizations(): Observable<IOrganization[]>;
    getUserResources(clientId: string, clientType?: string): Observable<IResource[]>;
    checkResourceAccess(
        clientId: string,
        clientType: string,
        resource: string,
        accessLevel: "edit" | "any"
    ): Observable<boolean>;
    checkOrganizationAccess(clientId: string, clientType?: string): Observable<boolean>;
    searchUserPermission(
        clientsIds: string[],
        clientType?: string
    ): Observable<Record<string, string[]>>;

    initialize(applicationInstance: string, product: string, baseUrl: string): void;
}

export const NIGHTINGALE_AUTH_SERVICE = new InjectionToken<INightingaleAuthService>(
    "NightingaleAuthService"
);

export const NIGHTINGALE_USER = new InjectionToken<INightingaleUser>("NightingaleUser");
