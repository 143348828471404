import { HttpErrorResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { ServerGatewayBase, LG_APP_CONFIGURATION } from "@logex/framework/lg-application";
import { ErrorMessageService } from "@shared/services/error-message.service";
import { isArray, isDate, cloneDeep } from "lodash";
import { MonoTypeOperatorFunction, pipe, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

export class GatewayError {
    constructor(
        public message: string,
        public data: HttpErrorResponse,
        public status?: number,
        public forceQuiet?: boolean,
        public errors?: any
    ) {}
}

export function checkBackendError<T>(
    handleErrorResponse: (error: HttpErrorResponse) => Promise<string>
): MonoTypeOperatorFunction<T> {
    return pipe(
        catchError<any, any>(err => {
            if (err.error && err.error.Message) {
                const error = new GatewayError(
                    err.error.Message,
                    err,
                    err.status,
                    err.force_quiet,
                    [err.error]
                );
                handleErrorResponse(error.data);
                throw error;
            }
            const error = new GatewayError(
                `${err.status}: ${err.statusText}`,
                err,
                err.status,
                err.force_quiet
            );
            handleErrorResponse(error.data);
            throw error;
        }),
        map((result: T) => {
            const anyResult = result as any;

            if (anyResult?.ok === false) {
                const error = new GatewayError(
                    anyResult.message,
                    anyResult,
                    undefined,
                    undefined,
                    anyResult.details ? anyResult.details.errors : undefined
                );

                handleErrorResponse(error.data);
                throw error;
            }
            return result;
        })
    );
}

export class AppGatewayBase extends ServerGatewayBase {
    protected _errorMessageService = inject(ErrorMessageService);
    protected _appConfiguration = inject(LG_APP_CONFIGURATION);

    constructor() {
        super();
        this._setBaseUrl(this._appConfiguration.applicationRoot);
        this._fakeCache = false;
    }

    protected handleErrorResponse(error: HttpErrorResponse): Promise<string> {
        return this._errorMessageService.showErrorDialog(error);
    }

    protected override _wrapReadRequest<T>(
        request: Observable<T>,
        _method: string,
        _url: string
    ): Observable<T> {
        return super
            ._wrapReadRequest<T>(request, _method, _url)
            .pipe(checkBackendError<T>(this.handleErrorResponse.bind(this)));
    }

    protected override _wrapWriteRequest<T>(
        request: Observable<T>,
        _method: string,
        _url: string
    ): Observable<T> {
        return super
            ._wrapWriteRequest<T>(request, _method, _url)
            .pipe(checkBackendError<T>(this.handleErrorResponse.bind(this)));
    }

    protected _patchDates(data: any, dateProperties: string[]): any;
    protected _patchDates(data: any[], dateProperties: string[]): any[];
    protected _patchDates(data: any[] | any, dateProperties: string[]): any[] {
        if (!data || !dateProperties) return data;

        if (!isArray(data)) data = [data];
        for (const item of data) {
            for (const property of dateProperties) {
                const value = item[property];
                if (value == null) continue;
                item[property] = new Date(Date.parse(value));
            }
        }
        return data;
    }

    protected _getUnpatchedDates(data: any): any;
    protected _getUnpatchedDates(data: any[]): any[];
    protected _getUnpatchedDates(data: any[] | any): any | any[] {
        if (!data) return data;

        let single = false;
        if (!isArray(data)) {
            data = [data];
            single = true;
        }

        const patchResursive = (item: any): any => {
            if (typeof item === "object") {
                for (const key in item) {
                    if (!Object.prototype.hasOwnProperty.call(item, key)) continue;

                    const value: any = item[key];
                    // Check for string properties which look like dates.
                    if (isDate(value)) {
                        item[key] = this._dateToString(value);
                    } else {
                        patchResursive(value);
                    }
                }
            } else if (isArray(item)) {
                const l = item.length;
                for (let i = 0; i < l; ++i) {
                    const value: any = item[i];
                    if (isDate(value)) {
                        item[i] = this._dateToString(value);
                    } else {
                        patchResursive(value);
                    }
                }
            }
        };

        const result = cloneDeep(data);
        patchResursive(result);
        return single ? result[0] : result;
    }

    protected _dateToString(date: Date): string | null {
        if (date === null) return null;
        const y = date.getFullYear();
        const m = date.getMonth() + 1;
        const d = date.getDate();
        const h = date.getHours();
        const min = date.getMinutes();

        return (
            y +
            "-" +
            (m < 10 ? "0" + m : m) +
            "-" +
            (d < 10 ? "0" + d : d) +
            " " +
            (h < 10 ? "0" + h : h) +
            ":" +
            (min < 10 ? "0" + min : min)
        );
    }
}
