import { Injectable } from "@angular/core";
import { ApplicationEventTracerHubFilter, ApplicationTraceSeverity } from "@logex/framework/core";

@Injectable()
export class AppEventsFilter extends ApplicationEventTracerHubFilter {
    sendTrackTrace(
        trackerName: string,
        severity: ApplicationTraceSeverity,
        message: string,
        customProperties?: unknown
    ): boolean {
        if (trackerName === "ApplicationInsights") return true;

        return super.sendTrackTrace(trackerName, severity, message, customProperties);
    }

    sendTrackException(trackerName: string, exception: Error, customProperties?: unknown): boolean {
        if (trackerName === "ApplicationInsights") return true;

        return super.sendTrackException(trackerName, exception, customProperties);
    }
}
