import { registerLocaleData } from "@angular/common";
import localeNl from "@angular/common/locales/nl";
import localeFi from "@angular/common/locales/fi";
import localeSv from "@angular/common/locales/sv";
import localeEn150 from "@angular/common/locales/en-150";
import localeNb from "@angular/common/locales/nb";
import localeEnGb from "@angular/common/locales/en-GB";

import { Component, inject } from "@angular/core";
import { LG_APP_CONFIGURATION, LgMatTrackingService } from "@logex/framework/lg-application";
import { AppConfiguration } from "@shared/services/app-startup.service.types";

import { MatomoConfiguration } from "@shared/services/matomo-configuration.service";

@Component({
    selector: "app-root",
    template: "<router-outlet></router-outlet>"
})
export class AppComponent {
    constructor() {
        registerLocaleData(localeNl);
        registerLocaleData(localeFi);
        registerLocaleData(localeSv);
        registerLocaleData(localeEnGb);
        registerLocaleData(localeEn150);
        registerLocaleData(localeNb);

        const appConfiguration = inject(LG_APP_CONFIGURATION) as AppConfiguration;
        const matomoTracker = inject(LgMatTrackingService);

        if (appConfiguration.full.matomo?.siteId) {
            matomoTracker.init(new MatomoConfiguration(appConfiguration.full.matomo));
        }
    }
}
