import { Injectable, isDevMode, inject } from "@angular/core";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { HttpErrorResponse } from "@angular/common/http";
import formatServerError from "@shared/helpers/formatServerError";

@Injectable({ providedIn: "root" })
export class ErrorMessageService {
    private _confirmDialog = inject(LgPromptDialog);
    private _lgTranslate = inject(LgTranslateService);

    showErrorDialog(error?: HttpErrorResponse): Promise<string> {
        const message = this._getMessage(error);
        return this._confirmDialog.alert(
            this._lgTranslate.translate("APP._Common.Error_dialog_title"),
            message,
            {
                dialogType: "alert",
                columns: 5
            }
        );
    }

    _getMessage(error?: HttpErrorResponse): string {
        if (!isDevMode()) {
            return this._lgTranslate.translate("APP._Common.Error_dialog_defaut_text");
        }
        if (error) {
            if (error.status === 0) {
                return this._lgTranslate.translate("APP._Common.Error_backend_not_running");
            }

            return formatServerError(error);
        }
        return this._lgTranslate.translate("APP._Common.Error_dialog_defaut_text");
    }
}
