import { inject, Injectable } from "@angular/core";
import {
    IAppInfo,
    LG_APP_INFO,
    LG_APP_SESSION,
    LgMatTrackingService
} from "@logex/framework/lg-application";
import { BehaviorSubject, firstValueFrom, Observable } from "rxjs";

import { WorkflowGateway } from "./workflow.gateway";
import { Workflow } from "./workflow.types";

import { configureMatomoCustomDimensions } from "@shared/services/matomo-configuration.service";
import { NIGHTINGALE_USER } from "@shared/services/authorization";

@Injectable({ providedIn: "root" })
export class WorkflowService {
    private _gateway = inject(WorkflowGateway);
    private _currentWorkflow = new BehaviorSubject<Workflow | null>(null);
    private _session = inject(LG_APP_SESSION);
    private _matomoTracker = inject(LgMatTrackingService);
    private _appInfo: IAppInfo = inject(LG_APP_INFO);
    private _userInfo = inject(NIGHTINGALE_USER);

    get currentWorkflow$(): Observable<Workflow | null> {
        return this._currentWorkflow.asObservable();
    }

    get currentWorkflowId(): string | undefined {
        return this._currentWorkflow.value?.id;
    }

    async setCurrentWorkflow(clientId: number, workflowId: string): Promise<Workflow | null> {
        if (
            this._currentWorkflow.value?.clientId === clientId &&
            this._currentWorkflow.value?.id === workflowId
        ) {
            return this._currentWorkflow.value;
        }

        const workflow = await firstValueFrom(this._gateway.getWorkflow(clientId, workflowId));

        // todo: check permission by workflowType type
        // f.ex if (!this._userInfo.canAccessResource(workflow.type)) return null;

        this._currentWorkflow.next(workflow);
        if (workflow) {
            this._session.clientId = workflow.clientId;
            this._session.client = {
                id: workflow.clientId,
                name: workflow.clientName,
                code: workflow.clientCode
            };

            this._userInfo.ziekenhuiscode = workflow.clientId;
            this._userInfo.ziekenhuisnaam = workflow.clientName;
        }

        configureMatomoCustomDimensions(this._matomoTracker, {
            appInfo: this._appInfo,
            user: this._userInfo,
            appSession: this._session
        });

        return workflow;
    }
}
