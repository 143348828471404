import { Injectable, OnDestroy, inject } from "@angular/core";
import { LG_APP_CONFIGURATION } from "@logex/framework/lg-application";
import { AppConfiguration } from "@shared/services/app-startup.service.types";
import { WorkflowService } from "@shared/workflow";
import {
    NavigationLinkOption,
    OrchestrationNavigation,
    OrchestrationNavigationAccessMode,
    OrchestrationNavigationGateway
} from "./orchestration-navigation.gateway";
import {
    BehaviorSubject,
    Observable,
    Subject,
    filter,
    firstValueFrom,
    map,
    shareReplay,
    startWith,
    switchMap,
    tap
} from "rxjs";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { keyBy } from "lodash";
import { IDrilldownBreadcrumbEntry } from "@logex/framework/lg-layout";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { workflowUrlRegex } from "@shared/types/workflowUrlRegex";

@Injectable({
    providedIn: "root"
})
export class OrchestrationNavigationService implements OnDestroy {
    private _confirmationDialog = inject(LgPromptDialog);
    private _navigationGateway = inject(OrchestrationNavigationGateway);
    private _translateService = inject(LgTranslateService);
    private _workflowService = inject(WorkflowService);

    private _orchestrationUrl?: string;
    protected _destroyed$ = new Subject<void>();
    private _navigationOptions: NavigationLinkOption[] = [];
    private _navigationOptionsById: Record<string, NavigationLinkOption> = {};
    refreshNavigation$ = new BehaviorSubject<void>(undefined);

    breadcrumbDrilldownConfig$ = this.refreshNavigation$.pipe(
        switchMap(() => this.getOrchestrationNavigation$().pipe(startWith(null))),
        shareReplay(1),
        filter((val): val is OrchestrationNavigation => val !== null),
        map(() => this._getBreadcrumb())
    );

    constructor() {
        const configuration = inject(LG_APP_CONFIGURATION) as AppConfiguration;
        this._orchestrationUrl = configuration.full?.orchestrationUrl;
    }

    get orchestrationLandingPage(): string | undefined {
        return this._orchestrationUrl;
    }

    get orchestrationWorkflowPage(): string | undefined {
        const workflowId = this._workflowService.currentWorkflowId;
        if (workflowId != null && this._orchestrationUrl != null) {
            return `${this._orchestrationUrl}/workflows/${workflowId}`;
        }
        return this._orchestrationUrl;
    }

    navigateToOrchestrationLandingPage(): void {
        if (this.orchestrationLandingPage != null) {
            window.location.assign(this.orchestrationLandingPage);
        }
    }

    navigateToOrchestrationWorkflowPage(): void {
        if (this.orchestrationWorkflowPage != null) {
            window.location.assign(this.orchestrationWorkflowPage);
        }
    }

    private _getWorkflowPatternPath(url: string): string {
        const match = url.match(workflowUrlRegex);
        const clientId = match?.groups?.clientId ?? "";
        const workflowId = match?.groups?.workflowId ?? "";
        const domain = match?.groups?.domain ?? "";
        return this._createWorkflowPatternPath(domain, clientId, workflowId);
    }

    private _createWorkflowPatternPath(
        domain: string,
        clientId: string,
        workflowId: string
    ): string {
        const url = `${domain}/${clientId}/workflows/${workflowId}`;
        return url.toLowerCase();
    }

    private _getNavigationOptions(navigation: OrchestrationNavigation): NavigationLinkOption[] {
        if (!navigation) return [];
        Object.entries(navigation.urls).forEach(([key, value]) => {
            delete navigation.urls[key];
            navigation.urls[key.toLowerCase()] = value;
        });
        navigation.navigationalUrls = navigation.navigationalUrls.map(url => url.toLowerCase());
        let navigationalTargetIds = navigation?.navigationalUrls;
        if (!navigationalTargetIds || navigationalTargetIds.length === 0) {
            navigationalTargetIds =
                navigation?.urls != null
                    ? Object.keys(navigation?.urls).filter(code =>
                          code.toLowerCase().startsWith("mapdata")
                      )
                    : [];
        }
        const navigationOptions = navigationalTargetIds.reduce((options, targetId) => {
            const option = navigation?.urls[targetId];
            if (option && option.authorized && option.url != null) {
                const optionMapped = {
                    ...option,
                    id: this._getWorkflowPatternPath(option.url),
                    name: this._translateService.translate(
                        "APP._OrchestrationNavigation." + targetId
                    )
                } satisfies NavigationLinkOption;
                options.push(optionMapped);
            }
            return options;
        }, [] as NavigationLinkOption[]);

        return navigationOptions;
    }

    private _getCurrentNavigationOption(): NavigationLinkOption {
        /* to do - remove 'view' from path when 'input' and 'output' will be used */
        const currentLocationArr = window.location.href.split("/");
        if (currentLocationArr[currentLocationArr.length - 1] === "view") {
            currentLocationArr.pop();
        }
        const currentLocation = currentLocationArr.join("/");
        const currentUrl = this._getWorkflowPatternPath(currentLocation);
        return this._navigationOptionsById[currentUrl];
    }

    private _getBreadcrumb(): Array<IDrilldownBreadcrumbEntry<string>> {
        return [
            {
                sortById: true,
                getCurrent: () => this._getCurrentNavigationOption(),
                getOptions: () => this._navigationOptions,
                mapToName: (id: string) => this._navigationOptionsById[id]?.name || "",
                onReset: () => {
                    this.navigateToOrchestrationWorkflowPage();
                },
                onSet: (id: string) => {
                    const url = this._navigationOptionsById[id]?.url;
                    window.location.href = url ?? id;
                }
            }
        ];
    }

    getOrchestrationNavigation$(): Observable<OrchestrationNavigation> {
        return this._navigationGateway.getOrchestrationNavigationSteps().pipe(
            tap(navigation => {
                this._navigationOptions = this._getNavigationOptions(navigation);
                this._navigationOptionsById = keyBy(this._navigationOptions, o => o.id);
            })
        );
    }

    getRefreshedCurrentNavigationOption$(): Observable<NavigationLinkOption> {
        return this.getOrchestrationNavigation$().pipe(
            map(() => this._getCurrentNavigationOption())
        );
    }

    async isOrchestrationBlockingOperation(): Promise<boolean> {
        const option = await firstValueFrom(this.getRefreshedCurrentNavigationOption$());
        if (option && option.workflowAccessMode !== OrchestrationNavigationAccessMode.FullAccess) {
            const title = this._translateService.translate("APP._OrchestrationNavigation.Warning");
            const text = this._translateService.translate(
                "APP._OrchestrationNavigation.OrchestrationBlockingOperation",
                {
                    link: this.orchestrationWorkflowPage
                }
            );
            this._confirmationDialog.alert(title, text);
            return true;
        }
        return false;
    }

    public ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
