import { Input, Component, HostListener } from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";

@Component({
    selector: "lg-snooze-icon",
    templateUrl: "./lg-snooze-icon.component.html",
    host: {
        "[class.block-element]": "true"
    }
})
export class LgSnoozeIconComponent {
    @Input() set isReadonly(value: boolean) {
        this._isReadonly = toBoolean(value);
    }

    get isReadonly(): boolean {
        return this._isReadonly;
    }

    @Input() set isSnoozed(value: boolean) {
        this._isSnoozed = toBoolean(value);
    }

    get isSnoozed(): boolean {
        return this._isSnoozed;
    }

    @Input() set hasSnoozes(value: boolean) {
        this._hasSnoozes = toBoolean(value);
    }

    get hasSnoozes(): boolean {
        return this._hasSnoozes;
    }

    private _isReadonly = false;
    private _isSnoozed = false;
    private _hasSnoozes = false;

    @HostListener("click", ["$event"])
    _clickHandler(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();
    }
}
