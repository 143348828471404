import { NgModule } from "@angular/core";
import { RouterModule, Route } from "@angular/router";

import { AuthorizedComponent } from "./authorized.component";
import { OrganizationWorkflowGuard } from "@shared/guards/organization-workflow.guard";
import { BootGuard } from "@shared/guards/boot.guard";
import { EnsureAuthorizationUserInfoGuard } from "@shared/guards/ensureAuthorizationUserInfo.guard";

const routes: Route[] = [
    {
        path: "",
        redirectTo: "/boot/selectWorkflow",
        pathMatch: "full"
    },
    {
        path: "boot",
        loadChildren: () => import("./pages/boot/boot-pages.module").then(m => m.BootPagesModule)
    },
    {
        path: "",
        component: AuthorizedComponent,
        data: {
            navigationId: "root"
        },
        children: [
            {
                path: ":clientId/workflows/:workflowId",
                canActivate: [EnsureAuthorizationUserInfoGuard, OrganizationWorkflowGuard],
                loadChildren: () =>
                    import("./pages/mapdata/mapdata.module").then(m => m.MapDataModule)
            }
        ]
    },
    {
        path: "**",
        redirectTo: "/boot/accessDenied"
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            enableTracing: false
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
