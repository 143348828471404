import { Input, Component, HostListener } from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";

@Component({
    selector: "lg-flag-icon",
    templateUrl: "./lg-flag-icon.component.html",
    host: {
        "[class.block-element]": "true"
    }
})
export class LgFlagIconComponent {
    @Input() set isReadonly(value: boolean) {
        this._isReadonly = toBoolean(value);
    }

    get isReadonly(): boolean {
        return this._isReadonly;
    }

    @Input() set isFlagged(value: boolean) {
        this._isFlagged = toBoolean(value);
    }

    get isFlagged(): boolean {
        return this._isFlagged;
    }

    @Input() set hasFlags(value: boolean) {
        this._hasFlags = toBoolean(value);
    }

    get hasFlags(): boolean {
        return this._hasFlags;
    }

    private _isReadonly = false;
    private _isFlagged = false;
    private _hasFlags = false;

    @HostListener("click", ["$event"])
    _clickHandler(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();
    }
}
