import _ from "lodash";
import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LG_APP_CONFIGURATION } from "@logex/framework/lg-application";
import { urlConcat } from "@logex/framework/utilities";
import { firstValueFrom, Observable, Subject } from "rxjs";

// ----------------------------------------------------------------------------------
export interface Feature<Configuration = any> {
    name: string;
    typeName: string | null;
    isEnabled: boolean;
    configuration: Configuration | null;
}

// ----------------------------------------------------------------------------------
@Injectable({
    providedIn: "root"
})
export class FeaturesService {
    private _applicationConfiguration = inject(LG_APP_CONFIGURATION);
    private _httpClient = inject(HttpClient);

    constructor() {
        this._onChangedSubject = new Subject<void>();
        this._onChanged$ = this._onChangedSubject.asObservable();
    }

    private _featuresLookup: Record<string, Feature> = {};
    private _featuresByTypeLookup: Record<string, Feature[]> = {};
    private _onChangedSubject: Subject<void>;
    private _onChanged$: Observable<void>;

    // ----------------------------------------------------------------------------------
    get onChanged(): Observable<void> {
        return this._onChanged$;
    }

    async load(clientId: number, scenarioId: number): Promise<void> {
        const features = await firstValueFrom(
            this._httpClient.get<Feature[]>(
                urlConcat(this._applicationConfiguration.applicationRoot, "boot/features"),
                {
                    params: { clientId, scenarioId }
                }
            )
        );

        this._featuresLookup = _.keyBy(features, "name");
        this._featuresByTypeLookup = _.groupBy(features, "typeName");

        this._onChangedSubject.next();
    }

    getFeature<T = any>(name: string): Feature<T> {
        const feature = this._featuresLookup[name];

        if (feature == null) {
            throw Error(`Feature ${name} is not known`);
        }

        return feature;
    }

    getFeaturesByType(typeName: string): Feature[] {
        return this._featuresByTypeLookup[typeName] ?? [];
    }

    isFeatureEnabled(name: string): boolean {
        const feature = this.getFeature(name);
        return feature.isEnabled;
    }
}
