import * as _ from "lodash";
import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";

import { LgConsole } from "@logex/framework/core";
import { LgPromptDialog } from "@logex/framework/ui-core";
import {
    KeyValuePair,
    LG_APP_CONFIGURATION,
    LG_APP_INFO,
    LG_AUTHENTICATION_SERVICE,
    OidcAuthService,
    UiSettings,
    UserSettingsService,
    WellKnownSettingCode
} from "@logex/framework/lg-application";
import { urlConcat } from "@logex/framework/utilities";
import { LG_LOCALIZATION_SETTINGS } from "@logex/framework/lg-localization";
import { AppLgLocalizationSettings } from "./app-lg-localization-settings";
import {
    AppCombinedConfiguration,
    AppConfiguration,
    AppDynamicConfiguration,
    AppStaticConfiguration
} from "./app-startup.service.types";
import { NIGHTINGALE_AUTH_SERVICE, NIGHTINGALE_USER } from "./authorization";
declare let APP_VERSION: string;

// ----------------------------------------------------------------------------------
//
@Injectable({ providedIn: "root" })
export class AppStartupService {
    private _alert = inject(LgPromptDialog);
    private _appConfiguration = inject<AppConfiguration>(LG_APP_CONFIGURATION);
    private _appInfo = inject(LG_APP_INFO);
    private _authorizationService = inject(NIGHTINGALE_AUTH_SERVICE);
    private _http = inject(HttpClient);
    private _lgConsole = inject(LgConsole);
    private _localizationSettings = inject<AppLgLocalizationSettings>(LG_LOCALIZATION_SETTINGS);
    private _oidcAuthService = inject<OidcAuthService>(LG_AUTHENTICATION_SERVICE);
    private _userInfo = inject(NIGHTINGALE_USER);
    private _userSettingsService = inject(UserSettingsService);

    initialize(): () => Promise<void> {
        return async () => {
            try {
                this._lgConsole.debug("INIT...");

                const basePath = "/";
                this._addBaseHref(basePath);

                // TODO: Configure fall-back language first

                const staticConfig = await this._loadStaticConfiguration();

                this._applyTheme(staticConfig.instance);

                if (!(await this._authenticate(staticConfig, basePath))) {
                    // Authentication is not finished yet - redirect required
                    return;
                }
                // skip loading of dynamic configuration for Nightingale instance
                // await this._loadConfiguration(staticConfig);

                const dynamicConfig = {} as AppDynamicConfiguration;

                const config = _.merge(staticConfig, dynamicConfig);

                this._lgConsole.debug("Full configuration", config);

                this._fillAppConfiguration(config);

                this._configureAppInfo();

                this._authorizationService.initialize(
                    config.instance,
                    config.product,
                    config.services.authorization
                );

                this._userSettingsService.initialize({
                    instance: config.instance,
                    environment: config.environment,
                    url: config.services.userSettings
                });

                await this._configureLocalization(staticConfig);

                await this._fetchUserInfo();
            } catch (e: any) {
                this._lgConsole.error("Error initializing application", e);

                // TODO: Make normal error reporting
                await this._alert.alert("Error initializing application", e.message, {
                    allowClose: false
                });

                throw e;
            }
        };
    }

    private _addBaseHref(basePath: string): void {
        let baseEl = _.first(document.head.getElementsByTagName("base"));

        if (baseEl == null) {
            baseEl = document.createElement("base");
            baseEl.setAttribute("href", basePath);
            document.head.appendChild(baseEl);
        }
    }

    private _configureAppInfo(): void {
        Object.assign(this._appInfo, {
            productId: "dpl",
            versionNumber: APP_VERSION, // version from Application.csproj
            toolInstanceName: this._appConfiguration.instance,
            environment: this._appConfiguration.environment,
            userManualUrl: ""
            // fullAppName: string;
            // overrideCurrencyPrefix: string;
            // overrideCurrencySuffix: string;
        });
    }

    private _applyTheme(appInstance: string): void {
        const company = appInstance
            .match(/(?<=ApplicationInstance\.Nightingale\.)(\w+)(?=\..+)/)?.[0]
            ?.toLowerCase();

        if (company) {
            window.document.body.classList.add(`logex-theme--${company}`);
            const faviconElement = document.getElementById("favicon") as HTMLLinkElement;
            faviconElement.href = `favicon-${company}.ico`;
        }
    }

    private async _loadStaticConfiguration(): Promise<AppStaticConfiguration> {
        const configText = await firstValueFrom(
            this._http.get("appsettings.json", {
                responseType: "text"
            })
        );

        if (!configText) throw Error("Empty configuration received");

        // Strip line comments (in a very dumb way) and parse JSON
        const config: AppStaticConfiguration = JSON.parse(configText.replace(/^\s*\/\/.*$/gm, ""));

        // TODO: validate config

        // delete config.$schema; // RF: todo, why is this?
        this._lgConsole.debug("Static configuration received", config);
        return config;
    }

    private async _configureLocalization(config: AppStaticConfiguration): Promise<void> {
        this._localizationSettings.availableLanguages = config.availableLanguages.split(",");
        this._localizationSettings.fallbackLanguage = config.fallbackLanguage;
        this._localizationSettings.preferredLanguage = config.defaultLanguage;
        this._localizationSettings.locale = config.defaultLanguage;
        this._localizationSettings.currency = config.currency;

        // Get user"s language
        const uiSettings = _.first(
            await this._userSettingsService
                .get({ storageId: WellKnownSettingCode.uiSettings })
                .toPromise()
        ) as KeyValuePair<any, UiSettings>;

        if (uiSettings != null && uiSettings.value != null) {
            const lang = uiSettings.value.language;
            if (_.includes(this._localizationSettings.availableLanguages, lang)) {
                this._localizationSettings.preferredLanguage = lang;
                this._localizationSettings.locale = lang;
            }
        }
        this._localizationSettings.setReady();
    }

    private async _authenticate(
        config: AppStaticConfiguration,
        basePath: string
    ): Promise<boolean> {
        this._oidcAuthService.configure({
            authority: config.services.authentication.authority,
            clientId: config.services.authentication.clientId,
            audience: config.services.authentication.audience,
            type: config.services.authentication.type ?? "generic",
            basePath,
            redirectUrl: window.location.href
        });
        const authenticated = await this._oidcAuthService.login();

        if (!authenticated) return false;

        this._userInfo.userid = this._oidcAuthService.user.id;
        return true;
    }

    private async _fetchUserInfo(): Promise<void> {
        const userData = await firstValueFrom(this._authorizationService.getUserProfile());

        // TODO: Do something with user data
        this._lgConsole.debug("User profile", userData.accountId);
        this._userInfo.name = userData.firstName + " " + userData.lastName;
        this._userInfo.login = userData.email;
        this._userInfo.userid = userData.accountId.toString();
        this._userInfo.isDisabled = userData.isDisabled;
        this._userInfo.isInternal = userData.isInternal;
    }

    private async _loadConfiguration(
        config: AppStaticConfiguration
    ): Promise<AppDynamicConfiguration> {
        const data = await firstValueFrom(
            this._http.get<AppDynamicConfiguration>(
                urlConcat(config.services.configuration, "/configuration"),
                {
                    params: {
                        instance: config.instance,
                        environment: config.environment
                    },
                    headers: { "api-version": "1.0" }
                }
            )
        );

        this._lgConsole.debug("Dynamic configuration received", data);

        return data;
    }

    private _fillAppConfiguration(config: AppCombinedConfiguration): void {
        this._appConfiguration.full = config;

        this._appConfiguration.instance = config.instance;
        this._appConfiguration.environment = config.environment;

        this._appConfiguration.applicationInsightsInstrumentationKey =
            config.applicationInsights?.instrumentationKey;
        this._appConfiguration.applicationInsightsExcludeDomains =
            config.applicationInsights?.excludeDomains;

        this._appConfiguration.testMachine = "";
        if (config.environment !== "production") {
            if (config.environment === "localhost") {
                this._appConfiguration.testMachine = "dev";
            } else {
                this._appConfiguration.testMachine = `${config.instance} - ${config.environment}`;
            }
        }

        this._appConfiguration.applicationRoot = config.services.application;
        this._appConfiguration.logoutUrl = window.location.origin + "/boot/accessDenied";

        this._appConfiguration.markReady();
    }
}
