import { HttpErrorResponse } from '@angular/common/http';

export default function formatServerError(error: HttpErrorResponse): string {
    let errorText = 'Error status ' + error.status;
    if (error.error != null) {
        if (error.error.Message != null) {
            errorText = error.error.Message;
            if (error.error.ExceptionMessage != null) {
                errorText += `<br /><b>${error.error.ExceptionMessage}</b>`;
            }
            if (error.error.StackTrace != null) {
                errorText += `<br /><div class="confirm-message__section confirm-message__scrollable" ><b>Stack:</b><br />
                        ${error.error.StackTrace.replace(/\n/g, '<br/>')}</div>`;
            }
        } else {
            const find = /<b>\s*Description:\s*<\/b>([^<]*)<br>/.exec(error.error.data);
            if (find) {
                errorText = find[1];
            }
        }
    }

    return errorText;
}
