<lg-icon
    *ngIf="isFlagged"
    [icon]="'icon-flag-filled'"
    class="table__column-icon"
    [clickable]="!isReadonly"
></lg-icon>
<lg-icon
    *ngIf="!isFlagged && hasFlags"
    [icon]="'icon-flag-empty'"
    class="table__column-icon"
    [clickable]="!isReadonly"
></lg-icon>
<lg-icon
    *ngIf="!isFlagged && !hasFlags && !isReadonly"
    [icon]="'icon-flag-empty'"
    class="table__column-icon table__row__hover-only"
    [clickable]="true"
></lg-icon>
