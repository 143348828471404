import { IAppSession } from "@logex/framework/lg-application";
import { AppScenario } from "./scenario/app-scenario";

export interface AppSession extends IAppSession {
    scenario: AppScenario;
    // clientName: string;
    // clientAbbr: string;

    client: {
        id: number;
        code: string;
        name: string;
        abbr: string;
    };
}
export class AppSession {}
