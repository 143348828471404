import { InjectionToken } from "@angular/core";
import { IAppConfiguration } from "@logex/framework/lg-application";
import { SidebarHelpStandardLinks } from "@logex/framework/lg-layout";

export const APP_HELP_LINKS = new InjectionToken<SidebarHelpStandardLinks>("AppHelpLinks");

// Configuration
export interface ServicesConfiguration {
    authentication: {
        authority: string;
        clientId: string;
        audience: string;
        type?: "auth0" | "generic";
    };
    configuration: string;
    userSettings: string;
    authorization: string;
    application: string;
    downtime: string;
}

export interface MatomoInitConfig {
    statUrl: string;
    siteId?: number;
    siteDomains: string[];
    enableCrossDomainLinking: boolean;
}

export interface AppStaticConfiguration {
    instance: string;
    product: string;
    environment: string;
    availableLanguages: string;
    defaultLanguage: string;
    fallbackLanguage: string;
    currency: string;

    orchestrationUrl?: string;

    services: ServicesConfiguration;

    matomo: MatomoInitConfig;
}

export interface AppDynamicConfiguration {
    services: Pick<ServicesConfiguration, "userSettings" | "application">;

    applicationInsights: {
        instrumentationKey: string;
        excludeDomains: string[];
    };

    helpLinks: SidebarHelpStandardLinks;
}

export type AppCombinedConfiguration = AppStaticConfiguration & AppDynamicConfiguration;

export interface AppConfiguration extends IAppConfiguration {}

export class AppConfiguration implements IAppConfiguration {
    applicationInsightsInstrumentationKey = "";
    full!: AppCombinedConfiguration;

    constructor() {
        this.ready = new Promise<void>(resolve => {
            this.markReady = resolve;
        });
    }

    markReady!: () => void;
}
