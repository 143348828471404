<div class="body__holder">
    <lg-sidebar>
        <lg-sidebar-button
            [buttonClass]="'icon-product-logo_dpl lg-sidebar-btn__image--no-text'"
            [large]="true"
            [nameAlwaysVisible]="false"
            [onTop]="true"
            (click)="_navigateToOrchestrationWorkflowPage()"
        ></lg-sidebar-button>

        <!--MDT-213.temporary hide "MENU" button-->
        <!--<lg-sidebar-menu
            *lgSidebarPanel="
                let sidebar;
                nameLc: 'APP._Sidebar.Menu';
                icon: 'icon-menu';
                top: true;
                nameAlwaysVisible: true;
                nameClass: 'lg-sidebar-btn__name--menu';
                id: 'menu'
            "
            [sidebar]="sidebar"
        ></lg-sidebar-menu>-->

        <!--MDT-214.temporary hide "HELP" button-->
        <!--<lg-sidebar-help
            *lgSidebarPanel="
                let sidebar;
                nameLc: 'APP._Sidebar.Help';
                icon: 'icon-sidebar-help';
                top: false;
                id: 'help'
            "
            [versionClickable]="false"
        >
        </lg-sidebar-help>-->

        <lg-sidebar-account
            class="lg-sidebar-panel"
            *lgSidebarPanel="
                let sidebar;
                nameLc: 'APP._Sidebar.Account';
                icon: 'icon-account';
                top: false;
                id: 'account'
            "
            [onlineLink]="null"
        ></lg-sidebar-account>

        <ng-container>
            <lg-sidebar-settings
                *lgSidebarPanel="
                    let sidebar;
                    nameLc: 'APP._Sidebar.Settings';
                    icon: 'icon-settings';
                    top: false;
                    id: 'settings'
                "
                [sidebar]="sidebar"
            ></lg-sidebar-settings>
        </ng-container>

        <div *ngIf="_devMode">
            <lg-sidebar-dev-widget *lgSidebarWidget></lg-sidebar-dev-widget>
        </div>
    </lg-sidebar>

    <div class="body__content">
        <div class="breadcrumb-row">
            <div class="lg-breadcrumb">
                <div
                    class="lg-breadcrumb__element lg-breadcrumb__element--toolname"
                    style="cursor: pointer"
                    (click)="_navigateToOrchestrationLandingPage()"
                >
                    {{ "APP.DPL" | lgTranslate }}
                </div>
            </div>
            <lg-sibling-placeholder name="breadcrumb-row.left.second"></lg-sibling-placeholder>
            <lg-sibling-placeholder name="breadcrumb-row.right"></lg-sibling-placeholder>
        </div>
        <router-outlet></router-outlet>
    </div>

    <lg-slideout [width]="360" variant="right" id="filters-right-slideout">
        <lg-sibling-placeholder name="slideout-panel.right"></lg-sibling-placeholder>

        <ng-container *lgPlaceholderContent="'slideout-panel.right'">
            <lg-filters-panel [disableBookmarks]="true"></lg-filters-panel>
        </ng-container>
    </lg-slideout>
</div>
