import { Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { urlConcat } from "@logex/framework/utilities";
import {
    GetPermissions,
    GetResource,
    INightingaleAuthService,
    IOrganization,
    IResource,
    IUserProfile,
    NIGHTINGALE_USER
} from "./nightingale-auth.types";

@Injectable()
export class NightingaleAuthService implements INightingaleAuthService {
    private _http = inject(HttpClient);
    private _user = inject(NIGHTINGALE_USER);

    private _applicationInstance!: string;
    private _url!: string;
    private _product!: string;

    initialize(applicationInstance: string, product: string, baseUrl: string): void {
        this._applicationInstance = applicationInstance;
        this._product = product;
        this._url = urlConcat(baseUrl, "/api/v4");
    }

    getUserPermission(clientId: string, clientType = "Global"): Observable<string[]> {
        return this._http
            .get<GetPermissions[]>(urlConcat(this._url, "/user/permissions"), {
                params: {
                    applicationInstance: this._applicationInstance,
                    product: this._product,
                    organizationIdentifier: clientId,
                    organizationType: clientType
                }
            })
            .pipe(map(x => x.find(p => p.product === this._product)?.permissions || []));
    }

    searchUserPermission(
        clientsIds: string[],
        clientType = "Global"
    ): Observable<Record<string, string[]>> {
        return this._http
            .post<Array<GetPermissions & { clientId: number }>>(
                urlConcat(this._url, "/user/permissions/search"),
                {
                    params: {
                        applicationInstance: this._applicationInstance,
                        product: this._product,
                        organizationsIdentifiers: [clientsIds],
                        organizationType: clientType,
                        login: this._user.email,
                        impersonatee: this._user.impersonator
                    }
                }
            )
            .pipe(
                map(x =>
                    Object.fromEntries(
                        x
                            .filter(p => p.product === this._product)
                            .map(c => [c.clientId.toString(), c.permissions])
                    )
                )
            );
    }

    getUserProfile(): Observable<IUserProfile> {
        return this._http.get<IUserProfile>(urlConcat(this._url, "/user/profile"));
    }

    getUserOrganizations(): Observable<IOrganization[]> {
        return this._http.get<IOrganization[]>(urlConcat(this._url, "/user/organizations"), {
            params: {
                applicationInstance: this._applicationInstance
            }
        });
    }

    getUserResources(clientId: string, clientType = "Global"): Observable<IResource[]> {
        return this._http
            .get<GetResource[]>(urlConcat(this._url, "/user/resources"), {
                params: {
                    applicationInstance: this._applicationInstance,
                    product: this._product,
                    organizationIdentifier: clientId,
                    organizationType: clientType
                }
            })
            .pipe(map(x => x.find(resorce => resorce.product === this._product)?.resources || []));
    }

    checkResourceAccess(
        clientId: string,
        clientType = "Global",
        resource: string,
        accessLevel: "edit" | "any"
    ): Observable<boolean> {
        return this._http.get<boolean>(urlConcat(this._url, "access/resource"), {
            params: {
                applicationInstance: this._applicationInstance,
                product: this._product,
                organizationIdentifier: clientId,
                organizationType: clientType,
                resource,
                accessLevel
            }
        });
    }

    checkOrganizationAccess(clientId: string, clientType = "Global"): Observable<boolean> {
        return this._http.get<boolean>(urlConcat(this._url, "access/organization"), {
            params: {
                organizationIdentifier: clientId,
                organizationType: clientType
            }
        });
    }
}
