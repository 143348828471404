import * as _ from "lodash";
import { Component, ViewEncapsulation, Input, OnChanges, inject } from "@angular/core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgSimpleChanges } from "@logex/framework/types";

export interface UploadErrorItem {
    tab: string | null;
    error: string;
    errorParameter: any | null;
    row: number | null;
    column: string | null;
    columnName: string | null;
    content: string | null;
    context: any | null;
}

interface TranslatedErrorItem extends UploadErrorItem {
    $expanded: boolean;
    message: string;
    details: string;
}

@Component({
    selector: "lg-upload-errors",
    templateUrl: "./lg-upload-errors.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [useTranslationNamespace("APP._ImportErrorList")]
})
export class LgUploadErrorsComponent implements OnChanges {
    private _lgTranslate = inject(LgTranslateService);
    @Input("errors") errors?: UploadErrorItem[];

    @Input("overrides") overrides?: _.Dictionary<string>;

    @Input("parentTranslationNamespace") parentTranslationNamespace?: string;

    _translatedErrors: TranslatedErrorItem[] = [];

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    ngOnChanges(changes: LgSimpleChanges<LgUploadErrorsComponent>) {
        if (changes.parentTranslationNamespace && this.parentTranslationNamespace) {
            if (
                this.parentTranslationNamespace.charAt(
                    this.parentTranslationNamespace.length - 1
                ) !== "."
            ) {
                this.parentTranslationNamespace = this.parentTranslationNamespace + ".";
            }
        }
        if (changes.errors || changes.overrides || changes.parentTranslationNamespace) {
            this._prepareErrors();
        }
    }

    // ----------------------------------------------------------------------------------
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    private _prepareErrors() {
        if (!this.errors) {
            this._translatedErrors = [];
            return;
        }

        const expandAll = this.errors.length < 3;
        let originalLength = 0;
        if (this.errors.length > 100) {
            originalLength = this.errors.length;
            this.errors = _.take(this.errors, 100);
        }

        this._translatedErrors = _.map(this.errors, item => {
            const result = this._translateError(item);
            result.$expanded = expandAll;
            return result;
        });

        // this._allExpanded = expandAll;
        // this._anyExpandable = _.find( this._translatedErrors, e => e.details ) !== null;

        if (originalLength) {
            this._translatedErrors.push({
                $expanded: false,
                message: this._lgTranslate.translate(".Error_list_shortened", {
                    count: originalLength
                }),
                column: null,
                columnName: null,
                content: null,
                context: null,
                details: "",
                error: "",
                errorParameter: null,
                row: null,
                tab: null
            });
        }
    }

    // ----------------------------------------------------------------------------------
    private _translateError(item: UploadErrorItem): TranslatedErrorItem {
        const override = this.overrides?.[item.error];
        let message: string;
        if (override) {
            message = this._parentTranslate(override, item);
        } else {
            message = this._lgTranslate.translate("._Errors." + item.error, item);
        }

        let details = "";
        if (item.tab) {
            if (item.error === "tab_row_duplicity") {
                const detailOverride = this.overrides?.["tab_row_duplicity_details"];
                if (detailOverride) {
                    details = this._parentTranslate(detailOverride, {
                        json: JSON.stringify(item.errorParameter),
                        ...item
                    });
                } else {
                    details = this._lgTranslate.translate("._Errors.tab_row_duplicity_details", {
                        json: JSON.stringify(item.errorParameter),
                        ...item
                    });
                }
            } else if (item.columnName) {
                details = this._lgTranslate.translate(".Error_detail_column_name", item);
            } else if (item.column) {
                details = this._lgTranslate.translate(".Error_detail_column", item);
            } else if (item.row) {
                details = this._lgTranslate.translate(".Error_detail_row", item);
            } else {
                details = this._lgTranslate.translate(".Error_detail_tab", item);
            }
            if (item.content != null) {
                details += " ('" + item.content + "')";
            }
        }

        return {
            message,
            details,
            $expanded: false,
            ...item
        };
    }

    _parentTranslate(text: string, ...args: any): string {
        return this._lgTranslate.translate(this.parentTranslationNamespace + text, ...args);
    }
}
