import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, ErrorHandler, APP_INITIALIZER, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
    HttpClient,
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi
} from "@angular/common/http";
import { TranslateModule, TranslateCompiler } from "@ngx-translate/core";
import {
    CoreModule,
    LgConsoleConfiguration,
    ConsoleLogLevel,
    LgErrorHandler,
    ApplicationEventTracerHubFilter,
    LG_APPLICATION_MULTI_EVENT_TRACERS
} from "@logex/framework/core";
import { LG_ICON_LOADER_CONTENT, UiCoreModule } from "@logex/framework/ui-core";
import {
    LgLayoutModule,
    LgUserStorageFwUiStateService,
    LG_FW_UI_STATE_SERVICE
} from "@logex/framework/lg-layout";
import {
    ReferenceTranslateCompiler,
    LgLocalizationModule,
    useMessageFormatLocales,
    LG_LOCALIZATION_SETTINGS
} from "@logex/framework/lg-localization";
import {
    LG_USER_INFO,
    LG_APP_CONFIGURATION,
    LG_APP_INFO,
    LG_NAVIGATION,
    LgApplicationModule,
    LgAuthorizationInterceptor,
    LG_APP_DEFINITIONS,
    LG_APP_SESSION,
    LgApplicationPermissionsBase,
    LgMessageBusService,
    LgUserStorageGatewayV2,
    LG_APP_CONTROL,
    LG_AUTHENTICATION_SERVICE,
    LG_MESSAGE_BUS_SERVICE,
    LG_USER_STORAGE_SERVICE_GATEWAY,
    OidcAuthService,
    LG_MATOMO_CONFIGURATION,
    LG_POSTPONE_MATOMO_CONFIGURATION,
    LgMatTrackingService,
    LgApplicationInsightsService,
    LG_APPLICATION_INSIGHTS_CONFIGURATION,
    LgBackendApplicationInsightsConfiguration,
    LG_DEFINITIONS_HIERARCHY_SERVICE,
    LgDefinitionsHierarchyService,
    LG_APP_GET_PERIOD_NAME,
    LgAppInitializerService,
    EmptyAppInfo
} from "@logex/framework/lg-application";
import { LgFiltersetModule } from "@logex/framework/lg-filterset";
import { LgPivotTableModule } from "@logex/framework/lg-pivot-table";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthorizedComponent } from "./authorized.component";
import { navigation } from "./shared/app-navigation";
import { LgExportsModule } from "@logex/framework/lg-exports";
import { LG_HELP_TOOLTIP_CONFIG, UiToolboxModule } from "@logex/framework/ui-toolbox";
import { AppStartupService } from "@shared/services/app-startup.service";
import { SharedModule } from "@shared/shared.module";
import { AppSession } from "@shared/app-session";
import { AppLgLocalizationSettings } from "@shared/services/app-lg-localization-settings";
import { AppConfiguration } from "@shared/services/app-startup.service.types";
import { AppClientIdInterceptor } from "@shared/services/app-client-id-interceptor.service";
import { AppDefinitions } from "@shared/app-definitions.service";
import { IAppDefinitions } from "@shared/app-definition-types";
import iconAtlas from "./assets/app/svg-icons/app-icons.def";
import { FeaturesService } from "@shared/services/app-features.service";
import { AppEventsFilter } from "@shared/services/app-events-filter.service";
import { AppControlService } from "@shared/services/app-control.service";
import { NightingaleAuthService } from "@shared/services/authorization/nightingale-auth.service";
import {
    NIGHTINGALE_AUTH_SERVICE,
    NIGHTINGALE_USER
} from "@shared/services/authorization/nightingale-auth.types";
import { AppUser, ApplicationPermissions, NightingaleUser } from "@shared/services/authorization";

const getPeriodName: (version: any, showDataVersionPeriode: boolean) => string = (
    version,
    showDataVersionPeriode?
): string => {
    return version.jaar;
};

@NgModule({
    declarations: [AppComponent, AuthorizedComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            compiler: { provide: TranslateCompiler, useClass: ReferenceTranslateCompiler }
        }),
        FormsModule,
        CoreModule,
        UiCoreModule,
        UiToolboxModule,
        LgLocalizationModule.forRoot({
            localizationUrlPrefix: "localization"
        }),
        LgFiltersetModule.forRoot(),
        LgPivotTableModule,
        LgApplicationModule.forRoot(),
        LgExportsModule,
        LgLayoutModule,
        SharedModule,
        AppRoutingModule // last!
    ],
    providers: [
        {
            provide: LgConsoleConfiguration,
            useFactory: () => {
                const config = new LgConsoleConfiguration();
                config.logger("Logex.Application", ConsoleLogLevel.Error);
                return config;
            }
        },
        {
            provide: LG_APP_GET_PERIOD_NAME,
            useValue: getPeriodName
        },
        {
            provide: LG_USER_INFO,
            useValue: new AppUser()
        },
        {
            provide: NIGHTINGALE_USER,
            useFactory: () => new NightingaleUser(inject(LG_USER_INFO))
        },
        {
            provide: LG_APP_CONFIGURATION,
            useValue: new AppConfiguration()
        },
        {
            provide: LG_APP_SESSION,
            useValue: new AppSession()
        },
        {
            provide: LG_APP_INFO,
            useValue: new EmptyAppInfo()
        },
        useMessageFormatLocales([
            "nl",
            "nl-NL",
            "en",
            "en-GB",
            "en-150",
            "sv-SE",
            "nb-NO",
            "fi-FI"
        ]),
        {
            provide: LG_APP_DEFINITIONS,
            useExisting: AppDefinitions
        },
        {
            provide: LG_NAVIGATION,
            useFactory: () => navigation(inject(FeaturesService))
        },
        {
            provide: LgApplicationPermissionsBase,
            useExisting: ApplicationPermissions
        },
        {
            provide: ErrorHandler,
            useClass: LgErrorHandler
        },
        {
            provide: LG_AUTHENTICATION_SERVICE,
            useClass: OidcAuthService
        },
        {
            provide: NIGHTINGALE_AUTH_SERVICE,
            useClass: NightingaleAuthService
        },
        {
            provide: LG_MESSAGE_BUS_SERVICE,
            useClass: LgMessageBusService
        },
        {
            provide: LG_LOCALIZATION_SETTINGS,
            useClass: AppLgLocalizationSettings
        },
        {
            provide: LG_FW_UI_STATE_SERVICE,
            useClass: LgUserStorageFwUiStateService
        },
        {
            provide: LG_APP_CONTROL,
            useClass: AppControlService
        },
        {
            provide: LG_USER_STORAGE_SERVICE_GATEWAY,
            useClass: LgUserStorageGatewayV2
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (startup: AppStartupService) => startup.initialize(),
            deps: [AppStartupService, HttpClient],
            multi: true
        },
        {
            provide: LgAppInitializerService,
            useValue: {
                initialize: () => Promise.resolve()
            } as any
        },
        {
            provide: LG_MATOMO_CONFIGURATION,
            useValue: LG_POSTPONE_MATOMO_CONFIGURATION
        },
        {
            provide: LG_APPLICATION_MULTI_EVENT_TRACERS,
            useExisting: LgMatTrackingService,
            multi: true
        },
        {
            provide: LG_APPLICATION_MULTI_EVENT_TRACERS,
            useExisting: LgApplicationInsightsService,
            multi: true
        },
        {
            provide: LG_APPLICATION_INSIGHTS_CONFIGURATION,
            useExisting: LgBackendApplicationInsightsConfiguration
        },
        {
            provide: ApplicationEventTracerHubFilter,
            useClass: AppEventsFilter
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LgAuthorizationInterceptor,
            multi: true
        },
        {
            provide: LG_ICON_LOADER_CONTENT,
            useValue: iconAtlas,
            multi: true
        },
        {
            provide: LG_DEFINITIONS_HIERARCHY_SERVICE,
            useFactory: () =>
                new LgDefinitionsHierarchyService<IAppDefinitions>(inject(AppDefinitions), {})
        },
        {
            provide: LG_HELP_TOOLTIP_CONFIG,
            useValue: {
                appId: "mapdata",
                isDisabled: false
            }
            // useFactory: (features: ILgFeatures) => {
            //     const config: IHelpTooltipConfig = {
            //         appId: "costing",
            //         isDisabled: false
            //     };
            //     // features.onChanged.subscribe( () => {
            //     //     config.isDisabled = !features.isFeatureEnabled( AppFeatures.SHOW_HELP_TOOLTIPS );
            //     // } );
            //     return config;
            // }
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule {}
