import { Pipe, PipeTransform } from "@angular/core";

export interface DisplayFormattedStringOpt {
    separator?: string;
    wrapInBrackets?: "round" | "square" | "angle" | "curly";
}

@Pipe({
    name: "asFormattedString",
    pure: true
})
export class DisplayFormattedStringPipe implements PipeTransform {
    private _defaultOpts: DisplayFormattedStringOpt = {
        separator: ", ",
        wrapInBrackets: undefined
    };

    private _wrapWith = (brackets: string | undefined, value: string): string => {
        switch (brackets) {
            case "round":
                return "(" + value + ")";
            case "square":
                return "[" + value + "]";
            case "angle":
                return "<" + value + ">";
            case "curly":
                return "{" + value + "}";
            default:
                return value;
        }
    };

    transform(value?: string[] | string | null, options?: DisplayFormattedStringOpt): string {
        if (value == null || value?.length === 0) return "−";
        const emptyOrWhitepaces = /^\s*$/;
        let resultStr = "";
        if (Array.isArray(value)) {
            resultStr = value
                ?.map(x => (emptyOrWhitepaces.test(x) ? '"' + x + '"' : x))
                .join(options?.separator ?? this._defaultOpts.separator);
        } else resultStr = emptyOrWhitepaces.test(value) ? '"' + value + '"' : value;
        return this._wrapWith(
            options?.wrapInBrackets ?? this._defaultOpts.wrapInBrackets,
            resultStr
        );
    }
}
