import { INavNode } from "@logex/framework/lg-application";
import { includes, isEmpty } from "lodash";
import { concat, merge, Observable, of, Subject } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { AppFeatures, HidePagesFeatureConfiguration } from "./app-features";
import { FeaturesService } from "./services/app-features.service";
import { ApplicationPermissions } from "@shared/services/authorization";

const navTree: INavNode[] = [
    {
        path: "",
        id: "root",
        noBreadcrumb: true,
        children: [
            {
                path: "{{clientId}}/workflows/{{workflowId}}/view",
                id: "mapdata",
                lid: ".MapData",
                permissions: [ApplicationPermissions.View]
            }
        ]
    },
    {
        path: "boot/selectWorkflow",
        permissions: [ApplicationPermissions.View],
        lid: ".SelectWorkflow"
    }
];

export const rebuildNavigationSubject = new Subject<void>(); // not used - for future purposes

export const navigation = (features: FeaturesService): Observable<INavNode[]> =>
    concat(
        of(navTree),
        merge(rebuildNavigationSubject, features.onChanged).pipe(
            debounceTime(10),
            map(() => {
                const feature = features.getFeature(AppFeatures.HIDE_PAGES);
                const configuration = feature.configuration as HidePagesFeatureConfiguration;
                const pages = (configuration && configuration.pages) ?? [];

                if (!feature.isEnabled || isEmpty(pages)) return navTree;

                return filterNavNodes(navTree, pages);
            })
        )
    );

function filterNavNodes(nodes: INavNode[], pagesToExclude: string[]): INavNode[] {
    const res: INavNode[] = [];
    for (let node of nodes) {
        if (includes(pagesToExclude, node.id)) continue;

        if (!isEmpty(node.children)) {
            node = {
                ...node,
                children: filterNavNodes(node.children!, pagesToExclude)
            };
        }

        res.push(node);
    }
    return res;
}
