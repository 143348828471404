import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { catchError, Observable, of } from "rxjs";

import { AppGatewayBase } from "../bases/app-gateway-base";
import { Workflow } from "./workflow.types";

@Injectable({ providedIn: "root" })
export class WorkflowGateway extends AppGatewayBase {
    getWorkflow(clientId: number, workflowId: string): Observable<Workflow | null> {
        return this._get<Workflow>(`organizations/${clientId}/workflows/${workflowId}`).pipe(
            catchError((err: HttpErrorResponse): Observable<any> => {
                if (err.status === 404) return of(null);
                throw err;
            })
        );
    }
}
