<lg-icon
    *ngIf="isSnoozed"
    [icon]="'icon-snooze-full'"
    class="table__column-icon"
    [clickable]="!isReadonly"
></lg-icon>
<lg-icon
    *ngIf="!isSnoozed && hasSnoozes"
    [icon]="'icon-snooze-empty'"
    class="table__column-icon"
    [clickable]="!isReadonly"
></lg-icon>
<lg-icon
    *ngIf="!isSnoozed && !hasSnoozes && !isReadonly"
    [icon]="'icon-snooze-empty'"
    class="table__column-icon table__row__hover-only"
    [clickable]="true"
></lg-icon>
