import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, UrlTree } from "@angular/router";
import {
    ApplicationPermissions,
    NIGHTINGALE_AUTH_SERVICE,
    NIGHTINGALE_USER,
    NightingaleUser
} from "@shared/services/authorization";
import { firstValueFrom } from "rxjs";

export async function EnsureAuthorizationUserInfoGuard(
    route: ActivatedRouteSnapshot
): Promise<boolean | UrlTree> {
    const clientId = route.paramMap.get("clientId");
    if (clientId === null) return false;

    const authorizationService = inject(NIGHTINGALE_AUTH_SERVICE);
    const user = inject<NightingaleUser>(NIGHTINGALE_USER);
    const router = inject(Router);

    const permissions = await firstValueFrom(authorizationService.getUserPermission(clientId));
    if (permissions.length === 0 || !permissions.includes(ApplicationPermissions.View))
        return router.parseUrl("/boot/selectWorkflow");

    user.roles = Object.fromEntries(permissions.map(x => [x, true]));

    const resources = await firstValueFrom(authorizationService.getUserResources(clientId));
    if (resources.length === 0) return router.parseUrl("/boot/selectWorkflow");

    user.resourcesCanEdit = Object.fromEntries(resources.map(x => [x.value, x.canEdit]));

    return true;
}
