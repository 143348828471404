import {
    IAppInfo,
    IAppSession,
    IMatomoCommonDimensionIds,
    IMatomoConfiguration,
    IUser,
    LgMatTrackingService
} from "@logex/framework/lg-application";
import { MatomoInitConfig } from "./app-startup.service.types";

// setup of custom dimensions is same for all environments (at least for now)
const ORGANIZATION_CODE_DIMENSION = 1;

export function configureMatomoCustomDimensions(
    service: LgMatTrackingService,
    values: {
        appInfo: IAppInfo;
        user: IUser;
        appSession: IAppSession;
    }
): void {
    service.deleteCustomDimension(ORGANIZATION_CODE_DIMENSION);
    if (values.appSession.client?.id) {
        service.setCustomDimension(
            ORGANIZATION_CODE_DIMENSION,
            values.appSession.client.id.toString()
        );
    }
}

export class MatomoConfiguration implements IMatomoConfiguration {
    siteId: number;
    statUrl: string;
    commonDimensionIds: IMatomoCommonDimensionIds = {};
    siteDomains: string[] | undefined = [];
    enableCrossDomainLinking = false;
    autoInit = false;
    autoTrack = true;
    useUserEmail = true;

    constructor(config: MatomoInitConfig) {
        this.siteId = config.siteId!;
        this.statUrl = config.statUrl;
        if (config.enableCrossDomainLinking) {
            this.enableCrossDomainLinking = true;
            this.siteDomains = config.siteDomains;
        }
    }

    doNotDoMatTracking(): boolean {
        return false;
    }
}
