import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Pipe, PipeTransform, inject } from "@angular/core";
import { ILgFormatter, NumberFormatterFactory } from "@logex/framework/core";

@Pipe({
    name: "fmtIntegerCustom",
    pure: true
})
export class FormatIntegerCustomPipe implements PipeTransform {
    private _formatter: ILgFormatter<number>;

    constructor() {
        const formatterFactory = inject(NumberFormatterFactory);

        this._formatter = formatterFactory.create();
    }

    public transform(
        value: string | number | null | undefined,
        decimals = 0,
        forceSign = false,
        zeroDash = false, // default fmtInteger is set to true,
        forceFormat = true
    ): string {
        forceSign = coerceBooleanProperty(forceSign);

        this._formatter.options = {
            ...this._formatter.options,
            forceSign,
            decimals,
            zeroDash,
            forceFormat
        };
        const val = typeof value === "string" ? parseInt(value) : value;
        return this._formatter.format(val);
    }
}
