import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Pipe, PipeTransform, inject } from "@angular/core";

import { ILgFormatter, NumberFormatterFactory } from "@logex/framework/core";
import { LgTranslateService } from "@logex/framework/lg-localization";

@Pipe({
    name: "fmtPreviewValue",
    pure: true
})
export class FormatPreviewValuePipe implements PipeTransform {
    private _translateService = inject(LgTranslateService);
    private _formatter: ILgFormatter<number>;

    constructor() {
        const formatterFactory = inject(NumberFormatterFactory);

        this._formatter = formatterFactory.create();
    }

    public transform(
        value: string | number | boolean | null | undefined,
        decimals = 0,
        forceSign = false,
        zeroDash = false, // default fmtInteger is set to true,
        forceFormat = true
    ): string | number {
        if (typeof value === "number") {
            forceSign = coerceBooleanProperty(forceSign);

            this._formatter.options = {
                ...this._formatter.options,
                forceSign,
                decimals,
                zeroDash,
                forceFormat
            };

            return this._formatter.format(value);
        }

        if (value === null || value === undefined) {
            return `(${this._translateService.translate("APP._Formatting.Empty")})`;
        }

        if (typeof value === "boolean") {
            return Number(value);
        }

        return value;
    }
}
