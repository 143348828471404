import { Injectable, inject } from "@angular/core";
import { LgLocalizationSettings } from "@logex/framework/lg-localization";
import { LgLoaderService } from "@logex/framework/lg-layout";
import { UserSettingsService, WellKnownSettingCode } from "@logex/framework/lg-application";

@Injectable()
export class AppLgLocalizationSettings implements LgLocalizationSettings {
    private _lgLoaderService = inject(LgLoaderService);
    private _userSettingsService = inject(UserSettingsService);

    constructor() {
        this.languages = {};

        this.bootstrapDone = new Promise(resolve => {
            this.setReady = resolve;
        });
    }

    // ----------------------------------------------------------------------------------

    availableLanguages: string[] = [];
    fallbackLanguage!: string;
    languages: Record<string, any[]>;
    preferredLanguage!: string;
    locale!: string;
    currency!: string;
    bootstrapDone: Promise<void>;

    setReady!: () => void;

    // ----------------------------------------------------------------------------------

    setPreferredLanguage(lang: string): void {
        this.preferredLanguage = lang;
    }

    addStrings(lang: string, strings: _.Dictionary<any>): void {
        if (!this.languages[lang]) this.languages[lang] = [];
        this.languages[lang].push(strings);
    }

    async switchCurrentLanguage(lang: string): Promise<void> {
        this._lgLoaderService.show();

        await this._userSettingsService
            .set({
                key: { storageId: WellKnownSettingCode.uiSettings },
                value: { language: lang }
            })
            .toPromise();

        window.location.reload();
    }
}
