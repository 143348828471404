import { IUser } from "@logex/framework/lg-application";

export class AppUser implements IUser {
    name = "";
    roles: Record<string, boolean> = {};
    title = "";
    userid = "";
    ziekenhuiscode = 0;
    ziekenhuisnaam = "";

    constructor() {
        this.roles = {};
    }

    public hasPermission(role: string): boolean {
        return this.roles[role] || false;
    }
}
